<template>
    <div>
        <titulo-boletim />
        <filtro-boletim
            :filtro="filtro"
            @filtrar="getBoletimPorAno()"
            @limpar="limparFiltro"
        />
        <tabela-boletim
            :anoLetivo="filtro.anoLetivo"
            :boletim="boletim"
            @baixar-boletim="getBoletimPDF"
        />
    </div>
</template>
<script>
// utils e aux
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import pdf from '@/common/utils/pdf';
import mensagem from '@/common/utils/mensagem';

// services
import BoletimService from '@/common/services/boletim/boletim.service';

// Components:
import TituloBoletim from './components/TituloBoletim';
import TabelaBoletim from './components/TabelaBoletim';
import FiltroBoletim from './components/FiltroBoletim';

export default {
    components: {
        TituloBoletim,
        FiltroBoletim,
        TabelaBoletim,
    },
    data() {
        return {
            filtro: {
                matriculaId: '',
                anoLetivoId: '',
            },
            boletim: [],
        };
    },
    methods: {
        getBoletimPorAno() {
            this.$store.dispatch(START_LOADING);
            BoletimService.buscarBoletimPorAnoLetivo(
                this.filtro.matriculaId,
                this.filtro.anoLetivoId
            )
                .then((e) => {
                    this.boletim = e.data;
                })
                .catch((err) => mensagem.showErrors(err))
                .finally(() => this.$store.dispatch(STOP_LOADING));
        },
        getBoletimPDF() {
            // TODO:  puxar da rota quando ela estiver prontinha
            this.$store.dispatch(START_LOADING);
            BoletimService.baixarPDF({
                matriculaId: this.filtro.matriculaId,
                anoLetivoId: this.filtro.anoLetivoId
            })
                .then(({ data }) => {
                    if (data.documento) {
                        //converte os bytes em array:
                        let bytes = pdf.base64ToArrayBuffer(data.documento);
                        // converte o array em blob
                        let blob = new Blob([bytes], {
                            type: 'application/pdf',
                        });
                        // faz download do PDF:
                        var link = document.createElement('a');
                        link.href = window.URL.createObjectURL(blob);
                        link.download = data.nomeDocumento;
                        link.click();
                    }
                })
                .catch((err) => {
                    mensagem.showErrors(err);
                })
                .finally(() => {
                    this.$store.dispatch(STOP_LOADING);
                });
        },
        limparFiltro() {
            this.boletim = [];
        },
    },
};
</script>
