<template>
    <b-row>
        <b-col md="4" v-show="opcoes.alunosVinculados?.length > 0">
            <input-select-search
                ref="pessoa"
                :options="opcoes.alunosVinculados"
                :label="$t('ACADEMICO.ALUNO')"
                v-model="filtro.matriculaId"
                required
            />
        </b-col>

        <b-col md="4">
            <input-select-search
                ref="anoLetivo"
                :label="$t('ACADEMICO.ANO_LETIVO')"
                v-model="filtro.anoLetivoId"
                :options="opcoes.anosLetivos"
                required
            />
        </b-col>
    </b-row>
</template>

<script>
// utils & helpers
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import JwtService from '@/common/utils/jwt';

// services
import ContratoService from '@/common/services/contratos/contratos.service';

// components
import { InputSelectSearch } from '@/components/inputs';

export default {
    name: 'FiltroParecerDescritivo',
    props: {
        filtro: { type: Object, default: Object },
    },
    components: {
        InputSelectSearch,
    },
    data() {
        return {
            opcoes: {
                alunosVinculados: [],
                anosLetivos: [],
            },
        };
    },
    mounted() {
        this.getAlunosVinculadosResponsavel();
    },
    watch: {
        'opcoes.alunosVinculados': {
            handler(value) {
                if (value?.length > 0) {
                    this.filtro.matriculaId = null;
                    return;
                }
                this.filtro.matriculaId = JwtService.buscarMatricula();
            },
            immediate: true,
        },

        'filtro.matriculaId': {
            handler(value) {
                if (value) {
                    this.getAnosLetivosDoContrato();
                    this.limpar();
                }
            },
            deep: true,
            immediate: true,
        },

        'filtro.anoLetivoId': {
            handler(value) {
                if (value) {
                    this.filtrar();
                }
            },
            deep: true,
        },
    },
    methods: {
        // obter dados
        getAnosLetivosDoContrato() {
            this.$store.dispatch(START_LOADING);
            ContratoService.buscarPorMatricula(this.filtro.matriculaId)
                .then(({ data }) => {
                    this.opcoes.anosLetivos = data.itens
                        .reduce((anosLetivosUnicos, contratoAtual) => {
                            if (
                                !anosLetivosUnicos.some(
                                    (ano) =>
                                        ano.value === contratoAtual.anoLetivoId
                                )
                            ) {
                                anosLetivosUnicos.push({
                                    value: contratoAtual.anoLetivoId,
                                    text: contratoAtual.anoLetivoDescricao.toString(),
                                });
                            }
                            return anosLetivosUnicos;
                        }, [])
                        .sort((a, b) => a.text - b.text);
                })
                .catch((err) => {
                    mensagem.showErrors(err);
                })
                .finally(() => this.$store.dispatch(STOP_LOADING));
        },

        getAlunosVinculadosResponsavel() {
            this.opcoes.alunosVinculados =
                JwtService.buscarAlunosVinculados().reduce(
                    (alunosSemDuplicatas, aluno) => {
                        const existe = alunosSemDuplicatas.some(
                            (a) => a.value === aluno.matriculaId
                        );
                        if (!existe) {
                            alunosSemDuplicatas.push({
                                text: aluno.nome,
                                value: aluno.matriculaId,
                            });
                        }
                        return alunosSemDuplicatas;
                    },
                    []
                );
        },

        filtrar() {
            this.$emit('filtrar');
        },

        limpar() {
            if (this.opcoes.alunosVinculados > 0) {
                this.filtro = {
                    matriculaId: '',
                    anoLetivoId: '',
                };
            } else {
                this.filtro.anoLetivoId = '';
            }
            this.$emit('limpar');
        },
    },
};
</script>
