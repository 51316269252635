import ApiService from "@/common/api/api.service";

const BoletimService = {
    async buscarAnosLetivos() {
        let { data } = await ApiService.get(`AnoLetivo`);
        return data;
    },
    async buscarBoletimPorAnoLetivo(matriculaId, anoLetivoId) {
        let { data } = await ApiService.get(`Boletim/obter-todos?MatriculaId=
    ${matriculaId}&AnoLetivoId=${anoLetivoId}`);
        return data;
    },
    async baixarPDF() {
        //TODO: voltar aqui quando tiver endpoint da api
    },
};

export default BoletimService;
