<template>
    <div>
        <div class="d-flex justify-content-between mb-3 align-items-center">
            <div>
                <span class="d-block">
                    <strong> Curso: </strong>
                    {{ boletim[0]?.cursoDescricao ?? '-' }}
                </span>
                <span>
                    <strong> Turma: </strong>
                    {{ boletim[5]?.turmaDescricao ?? '-' }}
                </span>
            </div>

            <b-button @click="baixarBoletimPdf" variant="info">
                TODO: {{ $t('ACADEMICO.IMPRIMIR') }}</b-button
            >
        </div>
        <b-table
            :fields="fields"
            :items="boletim"
            :empty-text="$t('GERAL.NENHUM_REGISTRO')"
            head-variant="light"
            stacked="md"
            responsive
            show-empty
            striped
            hover
            small
        >
            <template #thead-top>
                <tr>
                    <th></th>
                    <th colspan="2">1° {{ $t('ACADEMICO.BIMESTRE') }}</th>
                    <th colspan="2">2° {{ $t('ACADEMICO.BIMESTRE') }}</th>
                    <th colspan="2">3° {{ $t('ACADEMICO.BIMESTRE') }}</th>
                    <th colspan="2">4° {{ $t('ACADEMICO.BIMESTRE') }}</th>
                    <th colspan="4"></th>
                </tr>
            </template>
            <template #cell(nota)="row">
                {{ row.item[row.field].nota }}
            </template>
            <template #cell(faltas)="row">
                {{ row.item[row.field].faltas }}
            </template>
            <template #cell(statusBoletim)="row">
                <b-badge
                    :variant="
                        row.item.statusBoletim === 'Aprovado'
                            ? 'success'
                            : 'secondary'
                    "
                    class="py-1"
                >
                    {{
                        row.item.statusBoletim != 0
                            ? row.item.statusBoletim
                            : '-'
                    }}
                </b-badge>
            </template>
        </b-table>
        <div v-if="!boletim.length > 0 && !anoLetivo" class="my-5">
            <span class="d-block text-center">{{
                $t('ACADEMICO.BOLETIM_SELECIONAR_ANO')
            }}</span>
        </div>
        <div v-if="!boletim.length > 0 && anoLetivo" class="my-5">
            <span class="d-block text-center">{{
                $t('ACADEMICO.BOLETIM_NA0_EXISTE')
            }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TabelaBoletim',
    props: {
        boletim: { type: Array, default: Array },
        anoLetivo: { type: String, default: '' },
    },
    data() {
        return {
            fields: [
                {
                    key: 'disciplinaDescricao',
                    sortable: true,
                    label: this.$t('ACADEMICO.DISCIPLINA'),
                },
                {
                    key: 'boletinsSegmentacao.0.nota',
                    label: this.$t('ACADEMICO.NOTA'),
                },
                {
                    key: 'boletinsSegmentacao.0.faltas',
                    label: this.$t('ACADEMICO.FALTAS'),
                },
                {
                    key: 'boletinsSegmentacao.1.nota',
                    label: this.$t('ACADEMICO.NOTA'),
                },
                {
                    key: 'boletinsSegmentacao.1.faltas',
                    label: this.$t('ACADEMICO.FALTAS'),
                },
                {
                    key: 'boletinsSegmentacao.2.nota',
                    label: this.$t('ACADEMICO.NOTA'),
                },
                {
                    key: 'boletinsSegmentacao.2.faltas',
                    label: this.$t('ACADEMICO.FALTAS'),
                },
                {
                    key: 'boletinsSegmentacao.3.nota',
                    label: this.$t('ACADEMICO.NOTA'),
                },
                {
                    key: 'boletinsSegmentacao.3.faltas',
                    label: this.$t('ACADEMICO.FALTAS'),
                },
                {
                    key: 'notaRecuperacao',
                    label: this.$t('ACADEMICO.RECUPERACAO'),
                },
                { key: 'media', label: this.$t('ACADEMICO.MEDIA') },
                {
                    key: 'totalFaltas',
                    label: this.$t('ACADEMICO.TOTAL_FALTAS'),
                },
                { key: 'statusBoletim', label: this.$t('ACADEMICO.SITUACAO') },
            ],
        };
    },
    methods: {
        baixarBoletimPdf() {
            this.$emit('baixar-boletim');
        },
    },
};
</script>
